import React from "react";
import Banner from "./Banner";
import TestimonialForm from "./TestimonialForm";

export default function Index() {
  return (
    <div>
        <Banner/>
      <TestimonialForm />
    </div>
  );
}
