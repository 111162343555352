import React from 'react'
import Banner from './Banner'
import About from './About'

export default function Index() {
  return (
    <div>
      <Banner/>
      <About/>
    </div>
  )
}
