import React from 'react'
import FormContact from './Form'
import OurLocation from './OurLocation'

export default function Contact() {
  return (
    <div>
        <FormContact/>
        <OurLocation/>
    </div>
  )
}
