import React from 'react'
import Banner from './Banner'
import Form from './Form'

export default function Index() {
  return (
    <div>
        <Banner/>
        <Form/>
    </div>
  )
}
